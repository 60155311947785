import React from "react";
import "./App.css";
import AddressData from "./components/AddressData";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div className="App">
                <section className="network-stats">
                    <div className="row">
                        <div className="tabs">
                            <AddressData />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default App;
