import React from "react";
import { Grid } from "gridjs-react";
import { html } from "gridjs";

class Transactions extends React.Component {
    render() {
        return (
            <div className="txgrid u-center u-padding-bottom-small">
                <Grid
                    data={this.props.txList}
                    columns={[
                        { name: "Type" },
                        {
                            name: "Link",
                            width: "12%",
                            formatter: (cell) =>
                                html(`<a href='https://fsnscan.com/tx/${cell}'>Block Explorer</a>`),
                        },
                        { name: "Value", formatter: (cell) => `${cell} FSN` },
                        { name: "Start Date" },
                        { name: "End Date" },
                    ]}
                    pagination={{
                        limit: 25,
                    }}
                />
            </div>
        );
    }
}

export default Transactions;
