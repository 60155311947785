import React from "react";
import RewardsData from "./RewardsData";

class Rewards extends React.Component {
    render() {
        return (
            <div className="profile u-padding-bottom-big">
                <div className="col-1-of-2 u-center">
                    <div className="profile__data">
                        <p className="profile__data--title">Last 7 Days</p>
                        <p className="profile__data--value">{this.props.lastSevenDays} FSN</p>
                    </div>
                </div>
                <div className="col-2-of-2 u-center">
                    <div className="profile__data">
                        <p className="profile__data--title">Last 30 Days</p>
                        <p className="profile__data--value">{this.props.lastThirtyDays} FSN</p>
                    </div>
                </div>
                <div className="col-1-of-2 u-center u-padding-bottom-small">
                    <div className="profile__data">
                        <p className="profile__data--title">Total Rewards</p>
                        <p className="profile__data--value">{this.props.rewardData.totalReward} FSN</p>
                    </div>
                </div>
                <div className="col-2-of-2 u-center">
                    <div className="profile__data">
                        <p className="profile__data--title">Unpaid Rewards</p>
                        <p className="profile__data--value">{this.props.rewardData.unPaidFSN} FSN</p>
                    </div>
                </div>
                <RewardsData blockData={this.props.blockData} />
            </div>
        );
    }
}

export default Rewards;
