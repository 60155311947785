import React from "react";
import { Grid } from "gridjs-react";

class RewardTable extends React.Component {
    render() {
        return (
            <div className="txgrid u-center">
                <Grid
                    data={() => {
                        return new Promise((resolve) => {
                            setTimeout(() => resolve(this.props.blockData), 1000);
                        });
                    }}
                    columns={[
                        {
                            name: "Date",
                            id: "startDate",
                            formatter: (cell) => {
                                return new Date(cell).toUTCString();
                            },
                        },
                        { name: "Stake Amount", formatter: (cell) => (cell !== "Unavailable" ? `${cell.toFixed(2)} FSN` : cell) },
                        { name: "Reward", formatter: (cell) => (cell !== "Unavailable" ? `${cell.toFixed(2)} FSN` : cell) },
                        { name: "Fee", formatter: (cell) => (cell !== "Unavailable" ? `${(cell * 100).toFixed(2)} %` : cell) },
                        { name: "Payout", formatter: (cell) => `${cell} FSN` },
                    ]}
                    pagination={{
                        limit: 10,
                    }}
                />
            </div>
        );
    }
}

export default RewardTable;
