import React from "react";
import RewardChart from "./RewardChart";
import RewardTable from "./RewardTable";

import { json2csv } from 'json-2-csv';

class RewardsData extends React.Component {
    constructor(props) {
        super(props);
        this.state = { toggle: true };
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    handleToggleClick() {
        this.setState((state) => ({
            toggle: !state.toggle,
        }));
    }

    downloadAsCsv() {
        json2csv(this.props.blockData, (err, csv) => {
            if (err) {
                throw err;
            }

            this.generateDownload(csv)
        });
    }

    generateDownload(data) {
        const blob = new Blob([data], {type : 'text/csv" '});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'download.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        const toggle = this.state.toggle;
        return (
            <div className="rewards u-center">
                {toggle ? <RewardTable blockData={this.props.blockData} /> : <RewardChart blockData={this.props.blockData} />}
                <button onClick={this.handleToggleClick}>{this.state.toggle ? "Graph View" : "Table View"}</button>
                <br></br>
                <button onClick={() => this.downloadAsCsv()}>Download as CSV</button>
            </div>
        );
    }
}

export default RewardsData;
