import React from "react";
import { Bar } from "react-chartjs-2";

function getLabels(data) {
    var labels = [];
    for(const d of data) {
        labels.push(new Date(d.startDate).toDateString())
    }

    return labels.reverse();
}

function getRewards(data) {
    var rewards = [];
    for(const d of data) {
        rewards.push(d.payout)
    }

    return rewards.reverse();
}

function getStakeAmounts(data) {
    var stakeAmounts = [];
    for(const d of data) {
        stakeAmounts.push(d.stakeAmount.toFixed(2))
    }

    return stakeAmounts.reverse();
}

class RewardChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
    }

    render() {
        return (
            <Bar
                ref={this.chartReference}
                data={{
                    labels: getLabels(this.props.blockData),
                    datasets: [
                        {
                            label: "Daily Rewards",
                            backgroundColor: "#E1BEE7",
                            borderColor: "#7B1FA2",
                            borderWidth: 1,
                            hoverBackgroundColor: "#9C27B0",
                            hoverBorderColor: "#9C27B0",
                            data: getRewards(this.props.blockData),
                            barThickness: "flex",
                            maxBarThickness: "8",
                            yAxisID: 'right-y-axis'
                        },
                        {
                            label: "Daily Active Stake",
                            backgroundColor: "#F5F5F5",
                            borderColor: "#616161",
                            borderWidth: 1,
                            hoverBackgroundColor: "#9E9E9E",
                            hoverBorderColor: "#9E9E9E",
                            data: getStakeAmounts(this.props.blockData),
                            barThickness: "flex",
                            maxBarThickness: "8",
                            yAxisID: 'left-y-axis'
                        }
                    ],
                }}
                options={{
                    scales: {
                        yAxes: [{
                            id: 'left-y-axis',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true
                            }
                        }, {
                            id: 'right-y-axis',
                            type: 'linear',
                            position: 'right',
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }}
            />
        );
    }
}

export default RewardChart;
