import React from "react";

class Overview extends React.Component {
    render() {
        return (
            <div className="profile">
                <div className="col-1-of-2 u-center">
                    <div className="profile__data">
                        <p className="profile__data--title">Active Stake</p>
                        <p className="profile__data--value">{this.props.overviewData.activeStake} FSN</p>
                    </div>

                    <div className="profile__data">
                        <p className="profile__data--title">Staking Expiry</p>
                        <p className="profile__data--value">{this.props.overviewData.stakeExpiry}</p>
                    </div>
                </div>
                <div className="col-2-of-2 u-center">
                    <div className="profile__data">
                        <p className="profile__data--title">Fee</p>
                        <p className="profile__data--value">{this.props.overviewData.fee} %</p>
                    </div>
                    <div className="profile__data">
                        <p className="profile__data--title">Time-Lock Expiry</p>
                        <p className="profile__data--value">{this.props.overviewData.tlExpiry}</p>
                    </div>
                </div>
                <p className="profile__title" />
                <div className="flex-parent u-padding-bottom-big">
                    <div className="input-flex-container">
                        <div className="input">
                            <span data-year="T" data-info="Deposit" />
                        </div>
                        <div className="input">
                            <span data-year="T + 1Hr" data-info="Staking Started" />
                        </div>
                        <div className="input active">
                            <span data-year="Tx" data-info="Request Withdrawal" />
                        </div>
                        <div className="input">
                            <span data-year="Tx + 30 Days" data-info="Withdrawal Received" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Overview;
