import React from "react";
import Transactions from "./Transactions";
import Overview from "./Overview";
import Rewards from "./Rewards";

class AddressData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txList: [],
            overviewData: { activeStake: 0, fee: 0, stakeExpiry: "∞", tlExpiry: "∞" },
            rewardData: { totalReward: 0, unPaidFSN: 0 },
            blockData: [],
            lastSevenDays: 0,
            lastThirtyDays: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        if (event.target.value.length === 42) {
            this.getUserData(event.target.value.toLowerCase());
        }

        event.preventDefault();
    }

    getUserData = (_address) => {
        fetch("accounts/" + _address)
            .then((response) => response.json())
            .then((message) => {
                this.setState({ txList: message.txList, overviewData: message.overviewData, rewardData: message.rewardData });
                this.getLastRewardsSeven(_address, 7)
                this.getLastRewardsThirty(_address, 30)
                this.getBlockData(_address);
            })
            .catch((e) => {});
    };

    getBlockData = async (_address) => {
        fetch("blocks2?address=" + _address)
            .then((response) => response.json())
            .then((message) => {
                this.setState({ blockData: message.reverse() });
            })
            .catch((e) => {});
    };

    getLastRewardsThirty = async (_address, _numDays) => {
        fetch("/address/rewardDays?address=" + _address + "&numDays=" + _numDays)
            .then((response) => response.json())
            .then((message) => {
                this.setState({ lastThirtyDays: message[0].toFixed(2)});
            })
            .catch((e) => {});
    };

    getLastRewardsSeven = async (_address, _numDays) => {
        fetch("/address/rewardDays?address=" + _address + "&numDays=" + _numDays)
            .then((response) => response.json())
            .then((message) => {
                this.setState({ lastSevenDays: message[0].toFixed(2)});
            })
            .catch((e) => {});
    };

    render() {
        return (
            <div>
                <div className="search u-center">
                    <form onChange={this.handleChange}>
                        <input
                            className="search__input"
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Enter Public Address"
                            required
                            minLength={42}
                            maxLength={42}
                            size={50}
                        />
                    </form>
                </div>
                <input type="radio" id="tab1" name="tab-control" defaultChecked />
                <input type="radio" id="tab2" name="tab-control" />
                <input type="radio" id="tab3" name="tab-control" />
                <ul>
                    <li title="Overview">
                        <label htmlFor="tab1" role="button">
                            <br />
                            <span className="tabs__title">Overview</span>
                        </label>
                    </li>
                    <li title="Rewards">
                        <label htmlFor="tab2" role="button">
                            <br />
                            <span className="tabs__title">Rewards</span>
                        </label>
                    </li>
                    <li title="Transactions">
                        <label htmlFor="tab3" role="button">
                            <br />
                            <span className="tabs__title">Transactions</span>
                        </label>
                    </li>
                </ul>
                <div className="slider">
                    <div className="indicator" />
                </div>
                <div className="content">
                    <section>
                        <Overview overviewData={this.state.overviewData} />
                    </section>
                    <section>
                        <Rewards
                            rewardData={this.state.rewardData}
                            blockData={this.state.blockData}
                            lastSevenDays={this.state.lastSevenDays}
                            lastThirtyDays={this.state.lastThirtyDays}
                        />
                    </section>
                    <section>
                        <Transactions txList={this.state.txList} />
                    </section>
                </div>
            </div>
        );
    }
}

export default AddressData;
